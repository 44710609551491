import React from 'react'

function Organization () {
  return (
    <div id='organization' className='organization section-padding bg-pink'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12 mb-30'>
            <span className='oliven-title-meta'>Nuestra boda</span>
            <h2 className='oliven-title'>Organización</h2>
          </div>
        </div>
        <div className='row bord-box bg-img' data-background='images/slider.jpg'>
          <div className='col-md-3 item-box'>
            <h2 className='custom-font numb'>01</h2>
            <h6 className='title'>Ceremonia</h6>
            <p>
              La ceremonia civil se celebrará en los Jardines de Franchy el sábado 23 de noviembre de 2024 a las 12:00. Se ruega a los invitados acudir con antelación para no interferir en la celebración. El banquete será a continuación en el mismo sitio.
            </p>
          </div>
          <div className='col-md-3 item-box'>
            <h2 className='custom-font numb'>02</h2>
            <h6 className='title'>Etiqueta</h6>
            <p>
            Código de vestimenta: los hombres deberán vestir traje oscuro, camisa clara y corbata. Las mujeres vestidos tipo cóctel o traje de chaqueta, evitando el blanco o colores similares a la novia (beige, rosa claro, blanco roto…).
            </p>
          </div>
          <div className='col-md-3 item-box'>
            <h2 className='custom-font numb'>03</h2>
            <h6 className='title'>Niños</h6>
            <p>
            Para que todos puedan disfrutar plenamente de la celebración, agradecemos que los pequeños se queden en casa. Si tienes un bebé de menos de un año y lo quieres traer, consúltalo a los novios en el formulario que encontrarás más abajo.
            </p>
          </div>
          <div className='col-md-3 item-box'>
            <h2 className='custom-font numb'>04</h2>
            <h6 className='title'>Regalo</h6>
            <p>
            Si quieres sumar kilómetros a nuestra luna de miel aquí te dejamos nuestro número de cuenta:
            </p>
            <p>
            ES56 0182 5342 7202 0226 6972
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Organization
