import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function RSVP() {
  const [formData, setFormData] = useState({
    name: '',
    surnames: '',
    allergies: '',
    plusOne: false,
    partnerName: '',
    partnerAllergies: '',
    transport: false,
    transportFrom: '',
    message: ''
  });

  const navigate = useNavigate(); // Initialize useNavigate

  const handleCheckboxChange = (event, key) => {
    const value = event.target.checked;
    setFormData(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const handleTextChange = (event, key) => {
    const value = event.target.value;
    setFormData(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const handleDropdownChange = (event, key) => {
    const value = event.target.value;
    setFormData(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let myForm = document.getElementById("rsvp-form");
    let formData = new FormData(myForm);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => navigate('/thank-you')) // Navigate on success
      .catch((error) => alert(error));
  };

  return (
    <div id='rsvp' className='section-padding bg-img bg-fixed'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-6 bg-white p-40'>
            <span className='oliven-title-meta text-center'>¿Vendrás?</span>
            <h2 className='oliven-title text-center'>Confirma tu asistencia</h2>
            <br />
            <form name="rsvp" data-netlify="true" id="rsvp-form" onSubmit={submitHandler} className='row'>
              <input type="hidden" name="form-name" value="rsvp" />
              <div className='col-md-12'>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Nombre'
                    name='name'
                    value={formData.name}
                    onChange={(event) => handleTextChange(event, 'name')}
                    required
                  />
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Apellidos'
                    name='surnames'
                    value={formData.surnames}
                    onChange={(event) => handleTextChange(event, 'surnames')}
                    required
                  />
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Alergias o restricciones alimenticias'
                    name='allergies'
                    value={formData.allergies}
                    onChange={(event) => handleTextChange(event, 'allergies')}
                  />
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label className="checkbox-label">
                    <input
                      type='checkbox'
                      name='plusOne'
                      checked={formData.plusOne}
                      onChange={(event) => handleCheckboxChange(event, 'plusOne')}
                    />
                    <span className="checkbox-text">Llevaré a mi pareja</span>
                  </label>
                </div>
              </div>
              <div className={`col-md-12 conditional-field ${formData.plusOne ? 'visible' : ''}`}>
                <div className='form-group'>
                  <input
                    type='text'
                    name='partnerName'
                    value={formData.partnerName}
                    onChange={(event) => handleTextChange(event, 'partnerName')}
                    disabled={!formData.plusOne} // Disable if the boolean field is not checked
                    placeholder='Nombre completo de tu pareja'
                  />
                </div>
              </div>
              <div className={`col-md-12 conditional-field ${formData.plusOne ? 'visible' : ''}`}>
                <div className='form-group'>
                  <input
                    type='text'
                    name='partnerAllergies'
                    value={formData.partnerAllergies}
                    onChange={(event) => handleTextChange(event, 'partnerAllergies')}
                    disabled={!formData.plusOne} // Disable if the boolean field is not checked
                    placeholder='Alergias o restricciones alimenticias de tu pareja'
                  />
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label className="checkbox-label">
                    <input
                      type='checkbox'
                      name='transport'
                      checked={formData.transport}
                      onChange={(event) => handleCheckboxChange(event, 'transport')}
                    />
                    <span className="checkbox-text">Desearía transporte para la boda</span>
                  </label>
                </div>
              </div>
              <div className={`col-md-12 conditional-field ${formData.transport ? 'visible' : ''}`}>
                <div className='form-group'>
                  <label>Desde dónde</label>
                  <div className='select-wrapper'>
                    <select
                      className='form-control'
                      name='transportFrom'
                      value={formData.transportFrom}
                      onChange={(event) => handleDropdownChange(event, 'transportFrom')}
                    >
                      <option value='' disabled>Seleccione una opción</option>
                      <option value='ptocruz'>Puerto de la Cruz</option>
                      <option value='stacruz'>Santa Cruz de Tenerife</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    cols='30'
                    rows='7'
                    className='form-control'
                    value={formData.message}
                    onChange={(event) => handleTextChange(event, 'message')}
                    placeholder='Escribe aquí tu mensaje para los novios'
                  ></textarea>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group'>
                  <input type='submit' className='btn buttono' value='SEND' />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RSVP;
