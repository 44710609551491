import React from 'react'
import CarouselComponent from './Carousel'
import gallery4 from '../assets/images/4.jpg'


const importAll = (r) => {
  return r.keys().map(r);
};

const trips = importAll(require.context('../assets/images/nuestrosviajes', false, /\.(png|jpe?g|svg)$/));
const place = importAll(require.context('../assets/images/ellugar', false, /\.(png|jpe?g|svg)$/));
const us = importAll(require.context('../assets/images/nosotros', false, /\.(png|jpe?g|svg)$/));
const honeymoon = importAll(require.context('../assets/images/lunademiel', false, /\.(png|jpe?g|svg)$/));
const postals = importAll(require.context('../assets/images/laspostales', false, /\.(png|jpe?g|svg)$/));

function Gallery () {
  return (
    <div id='gallery' className='section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12 mb-30'>
            <span className='oliven-title-meta'>Galería</span>
            <h2 className='oliven-title'>Fotos</h2>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row gallery-filter mt-3'>
          <div className='col-md-4 gallery-item ceremony'>
            <div className='img-zoom'>
              <div className='gallery-box'>
                <div className='gallery-img'>
                  {' '}
                  <div>
                    <CarouselComponent images={trips} />
                  </div>
                  {' '}
                  <div className='gallery-detail'>
                    <h4 className='mb-0'>Nuestros viajes</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4 gallery-item party'>
            <div className='img-zoom'>
              <div className='gallery-box'>
                <div className='gallery-img'>
                  {' '}
                  <div>
                    <CarouselComponent images={place} />
                  </div>
                  {' '}
                </div>
                <div className='gallery-detail'>
                  <h4 className='mb-0'>El lugar</h4>{' '}
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4 gallery-item ceremony'>
            <div className='img-zoom'>
              <div className='gallery-box'>
                <div className='gallery-img'>
                  {' '}
                  <div>
                    <CarouselComponent images={us} />
                  </div>
                  {' '}
                </div>
                <div className='gallery-detail'>
                  <h4 className='mb-0'>Nosotros</h4>{' '}
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4 gallery-item ceremony'>
            <div className='img-zoom'>
              <div className='gallery-box'>
                <div className='gallery-img'>
                  {' '}
                  <div>
                    <CarouselComponent images={honeymoon} />
                  </div>
                  {' '}
                </div>
                <div className='gallery-detail'>
                  <h4 className='mb-0'>Nuestra luna de miel</h4>{' '}
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4 gallery-item party'>
            <div className='img-zoom'>
              <div className='gallery-box'>
                <div className='gallery-img'>
                  {' '}
                  <div>
                    <CarouselComponent images={postals} />
                  </div>
                  {' '}
                </div>
                <div className='gallery-detail'>
                  <h4 className='mb-0'>Las postales</h4>{' '}
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4 gallery-item party'>
            <div className='img-zoom'>
              <div className='gallery-box'>
                <div className='gallery-img'>
                  {' '}
                  <img
                    src={gallery4}
                    className='img-fluid mx-auto d-block'
                    alt=''
                  />{' '}
                </div>
                <div className='gallery-detail'>
                  <h4 className='mb-0'>La boda</h4>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gallery
