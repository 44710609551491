import React from 'react'
import where1 from '../assets/images/where-1.jpg'
import where2 from '../assets/images/where-2.jpg'
import where3 from '../assets/images/where-3.jpg'
function Where () {
  return (
    <div id='whenwhere' className='whenwhere section-padding bg-pink'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12 mb-30'>
            {' '}
            <span className='oliven-title-meta'>Preguntas frequentes</span>
            <h2 className='oliven-title'>Dónde y Cuándo</h2>{' '}
          </div>
        </div>
        <div className='row'>
          <div className='item col-12 col-md-4'>
            <div className='whenwhere-img'>
              {' '}
              <img src={where1} alt='' />
            </div>
            <div className='content'>
              <h5>La Boda</h5>
              <p>
                <i className='ti-location-pin'></i> Jardines de Franchy <br/>
                 C. Viera, 30, 38300 La Orotava, Santa Cruz de Tenerife
              </p>
              <p>
                <i className='ti-time'></i> <span>12:00</span>
              </p>
            </div>
          </div>
          <div className='item col-12 col-md-4'>
            <div className='whenwhere-img'>
              {' '}
              <img src={where2} alt='' />
            </div>
            <div className='content'>
              <h5>Transporte</h5>
              <p style={{ minHeight: '105px' }}>
                <i className='ti-location-pin mh-105'></i> Guaguas de ida y vuelta dependiendo de la demanda desde Puerto de la Cruz y/o Santa Cruz. Si estás interesado puedes indicarlo en el formulario más abajo.
              </p>
            </div>
          </div>
          <div className='item col-12 col-md-4'>
            <div className='whenwhere-img'>
              {' '}
              <img src={where3} alt='' />
            </div>
            <div className='content'>
              <h5>Alojamiento</h5>
              <p>
                <i className='ti-direction-alt'></i> Les recomendamos los siguientes hoteles:
              </p>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                  <li>Hotel Sol Costa Atlantis Tenerife</li>
                  <li>Be Live Experience Orotava</li>
                  <li>H10 Tenerife Playa</li>
                  <li>Hotel Vallemar</li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Where
