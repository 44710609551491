import React from 'react';

function LandingPage() {
  return (
    <div id='landing-page' className='section-padding bg-img bg-fixed'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-6 bg-white p-40 text-center'>
            <h2 className='oliven-title'>¡Gracias por confirmar tu asistencia!</h2>
            <p className='thank-you-message'>Estamos emocionados de que puedas unirte a nosotros en nuestro día especial</p>
            <a href="/" className='btn buttono'><span>Volver al inicio</span></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
