import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles

const CarouselComponent = ({ images }) => {
    return (
        <Carousel showThumbs={false} autoPlay={false} infiniteLoop={true} showStatus={false} swipeable={true} dynamicHeight={true} className="custom-carousel">
            {images.map((image, index) => (
                <div key={index}>
                    <img src={image} alt={`Slide ${index}`} />
                </div>
            ))}
        </Carousel>
    );
};

export default CarouselComponent;
