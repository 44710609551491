import React from 'react'
import storyImage from '../assets/images/story.jpg'
function Story () {
  return (
    <div id='story' className='story section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-5 mb-30'>
            <div className='story-img animate-box'>
              <div className='img'>
                {' '}
                <img src={storyImage} className='img-fluid' alt='' />{' '}
              </div>
              <div
                className='story-img-2 story-wedding'
              ></div>
            </div>
          </div>
          <div className='col-md-7 animate-box'>
            <h4 className='oliven-story-subtitle'>Así empezó</h4>
            <h3 className='oliven-story-title'>Nuestra historia</h3>
            <p>
            Nos conocimos en plena pandemia en un momento en el que el mundo era un caldo de incertidumbre. 
            Pablo, en aquel momento, era el jefe de residentes de Traumatología del HUC. Patricia una simple residente de primer año. 
            Pero no fue hasta un año y medio de roces que surgió el cariño, y con el cariño el amor. No, no fue amor a primera vista. 
            Pero en medio de tanta incertidumbre, fue un amor lleno de certezas. Así que con esa certeza nos aventuramos a irnos a vivir juntos después de cuatro meses de relación. 
            Si nos conoces un poco sabrás de sobra que Pablo no volvió a ser jefe de nada nunca más. Y aquí estamos, casi tres años después, deseando celebrar nuestro amor rodeados de las personas más importantes de nuestras vidas.
            </p>
            <p>
            Tres años nos han dado para mucho. 
            Hemos navegado el Lago di Como y paseado por La Habana al ritmo del son. 
            Hemos visto elefantes en Amboseli y hemos descubierto el tesoro escondido de Petra. 
            Sobrevolamos en globo la sabana africana y comimos cannoli en Sicilia. 
            Dormimos en el desierto de Wadi Rum y nos emocionamos con el azul de las cascadas de Plitvice. 
            Comimos pasta a la boloñesa en Bolonia y paseamos por las playas paradisíacas de Zanzíbar. 
            Nos hartamos a langosta en el cayo de Santa María y nos dimos un baño de sol y salitre en la isla de Hvar. 
            Sin duda, tres años nos han dado para mucho.
            </p>
            <h4>¡Nos casamos!</h4>
            <p>
            El día 23 de noviembre emprenderemos el que será el viaje más importante de nuestras vidas, y nos haría muy felices que pudieras celebrar este día con nosotros. Prometemos que será inolvidable.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Story
